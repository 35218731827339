import axios from 'axios'

const TicketService = {
  async getTickets (filters) {
    return axios({
      url: axios.defaults.baseURL + 'ticket/get/',
      data: JSON.stringify({ filters: filters }),
      method: 'post'
    })
  },

  async deleteTicket (filters) {
    return axios({
      url: axios.defaults.baseURL + 'ticket/delete/',
      data: JSON.stringify({ filters: filters }),
      method: 'post'
    })
  },

  async update (ticketId, info) {
    const data = {
      id: ticketId,
      info: info
    }
    return axios({
      url: axios.defaults.baseURL + 'ticket/update',
      method: 'POST',
      data: JSON.stringify({ data: data })
    })
  },

  async getTicketStatuses () {
    return axios({
      url: axios.defaults.baseURL + 'ticket/getStatuses',
      method: 'GET'
    })
  },

  async getNotes (ticketid) {
    return axios({
      url: axios.defaults.baseURL + 'ticket/getNotes/' + ticketid,
      method: 'GET'
    })
  },

  async reportingFilters () {
    return axios({
      url: axios.defaults.baseURL + 'ticket/reportingFilters/',
      method: 'GET'
    })
  },

  async breakdown (filters) {
    return axios({
      url: axios.defaults.baseURL + 'ticket/breakdown/',
      data: JSON.stringify({ filters: filters }),
      method: 'POST'
    })
  },

  async detail (filters) {
    return axios({
      url: axios.defaults.baseURL + 'ticket/breakdown/detail',
      data: JSON.stringify({ filters: filters }),
      method: 'POST'
    })
  },

  async export (filters) {
    return axios({
      url: axios.defaults.baseURL + 'ticket/breakdown/export',
      data: JSON.stringify({ filters: filters }),
      method: 'POST'
    })
  }
}

export default TicketService
